import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { NetworkService } from '../network/network.service';
import { AlertService } from '../alert/alert.service';
import { SpinnerService } from '../spinner/spinner.service';
import { RetornoModel } from '../../models/retornoModel';
import { Global } from '../../helpers/global';
import { UsuarioModel } from 'src/app/models/usuarioModel';
import { EmpresaModel } from 'src/app/models/empresaModel';
import { DetalheHorarioFuncionamentoModel, HorarioFuncionamentoModel } from 'src/app/models/horarioFuncionamentoModel';
import { GrupoModel, DetalheGrupoModel } from 'src/app/models/grupoModel';
import { ProdutoModel, DetalheProdutoModel, DetalheProdutoGrupoAcompanhamentoModel, DetalheGrupoAcompanhamentoModel } from 'src/app/models/produtoModel';
import { FormaPagamentoModel, DetalheFormaPagamentoModel } from 'src/app/models/FormaPagamentoModel';
import { CarrinhoModel } from 'src/app/models/carrinhoModel';
import { PedidoModel, DetalhePedidoModel } from 'src/app/models/pedidoModel';
import { FuncionamentoModel } from 'src/app/models/FuncionamentoModel';
import { AcompanhamentoModel, AcompanhamentoPedidoProdutoModel, DetalheAcompanhamentoModel } from 'src/app/models/acompanhamentoModel';
import { IdentificacaoModel } from 'src/app/models/identificacaoModel';
import { enFormaBusca } from 'src/app/models/enums/enFormaBusca';
import { enTipoAcompanhamento } from 'src/app/models/enums/enTipoAcompanhamento';
import { WeekDay } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(
    private http: HttpClient,
    private alertSrv: AlertService,
    private spinnerSrv: SpinnerService,
    private networkSrv: NetworkService) {
  }

  private ObterHeaderHttp(): HttpHeaders {
    let header = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'OPTIONS,GET,POST,PUT,DELETE',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
      'Accept': 'application/json',
      'content-type': 'application/json'
    });

    return header;
  }

  public VerificaServicoOnline(): Promise<RetornoModel> {
    return new Promise((resolve) => {
      let retorno = new RetornoModel();
      retorno.Sucesso = true;
      resolve(retorno);


      // //this.spinnerSrv.Show("Verificando conexão...");
      // let url = Global.UrlServico + 'IsServicoOnline/';

      // let retorno = new RetornoModel();
      // if (!Global.EhProducao) {
      //   retorno.Sucesso = true;

      //   resolve(retorno);
      // }
      // else {
      //   let header = this.ObterHeaderHttp();

      //   let options = {
      //     headers: header
      //   };

      //   if (!Global.EhProducao) {
      //     console.log(header);
      //     console.log(options);
      //   }

      //   if (Global.AmbienteMocado) {
      //     retorno.Sucesso = true;
      //     resolve(retorno);
      //   }
      //   else if (this.networkSrv.isOnline) {
      //     this.http.get(url, options).subscribe(
      //       result => {
      //         //this.spinnerSrv.Hide();
      //         retorno.Sucesso = true;

      //         if (!Global.EhProducao) {
      //           console.log('Sucesso!!!');
      //           console.log(result);
      //         }

      //         resolve(retorno);
      //       },
      //       err => {
      //         //this.spinnerSrv.Hide();

      //         retorno.Mensagem = 'Servidor offline. Tente novamente';
      //         retorno.Erro = err;

      //         if (!Global.EhProducao) {
      //           console.log('Falha!!!');
      //           console.log(err);
      //         }

      //         this.alertSrv.Mensagem(retorno.Mensagem);

      //         resolve(retorno);
      //       });
      //   }
      //   else {
      //     retorno.Mensagem = 'Seu dispositivo está offline';
      //     this.alertSrv.Mensagem(retorno.Mensagem);

      //     resolve(retorno);
      //   }
      // }
    });
  }

  public async RealizarLoginUsuario(usuario: string, senha: string, codigoSmsAutorizacao: string): Promise<UsuarioModel> {
    let c = () => {
      let retorno: UsuarioModel = new UsuarioModel();

      return new Promise(async (resolve) => {
        if (usuario && senha) {

          // Monto o objeto que vou "zipar"
          let obj = {
            login: usuario,
            senha: senha,
            codigoSmsAutorizacao: codigoSmsAutorizacao
          }
          let jsonUsuario: string = JSON.stringify(obj);
          let zip: string = jsonUsuario; // string = ''; // Zip.Compress(jsonUsuario);

          // Monto o objeto que vou serializar no POST
          let p = {
            id: Global.IdentificacaoEmpresa,
            obj: zip
          }
          let jsonFinal: string = JSON.stringify(p);

          let url = Global.UrlServico + 'aut/login/';
          let header = this.ObterHeaderHttp();
          let options = {
            headers: header
          };

          if (!Global.EhProducao) {
            console.log(header);
            console.log(options);
            console.log(jsonUsuario);
            console.log(zip);
            console.log(jsonFinal);
          }

          let online = await this.VerificaServicoOnline();

          if (online.Sucesso) {
            this.spinnerSrv.Show('Realizando login...', 2000);

            await this.http.post(url, jsonFinal, options).subscribe(
              result => {
                //this.spinnerSrv.Hide();

                if (!Global.EhProducao) {
                  console.log('Sucesso!!!');
                  console.log(result);
                }

                let r: any = result;
                if ((r.S) && (r.O)) {
                  let objResp = JSON.parse(r.O);

                  retorno.Sucesso = r.S;
                  retorno.Mensagem = r.ME;
                  retorno.Codigo = objResp.codigo;
                  retorno.Login = objResp.login;
                  retorno.Nome = objResp.nome;
                  retorno.Autenticado = objResp.autenticado;
                  retorno.EstaCadastrado = objResp.estaCadastrado;

                  retorno.Cep = objResp.cep;
                  retorno.Endereco = objResp.endereco;
                  retorno.Bairro = objResp.bairro;
                  retorno.Cidade = objResp.cidade;
                  retorno.NumeroResidencia = objResp.numeroResidencia;
                  retorno.Complemento = objResp.complemento;
                  retorno.Telefone = objResp.telefone;

                  retorno.listaNegra = objResp.listaNegra;
                }
                else {
                  retorno.Mensagem = Global.isNullOrEmpty(r.ME)
                    ? r.ME
                    : 'Nenhuma resposta válida do servidor';
                }

                resolve(retorno);
              },
              err => {
                //this.spinnerSrv.Hide();

                retorno.Mensagem = 'Não foi possível autenticar este telefone ou usuário';

                resolve(retorno);
              });
          }
          else {
            retorno.Mensagem = online.Mensagem;
            resolve(retorno);
          }
        }
        else {
          this.spinnerSrv.Hide();
          retorno.Mensagem = 'Informe um telefone ou usuário e senha válidos';
          resolve(retorno);
        }
      });
    };

    let ret: UsuarioModel = new UsuarioModel();
    if (Global.AmbienteMocado) {
      ret.Sucesso = true;
      ret.Mensagem = '';
      ret.Codigo = 1;
      ret.Login = 'JSOUZA';
      ret.Nome = 'Jairo Azevedo';
      ret.Autenticado = true;
      ret.EstaCadastrado = true;
      ret.Cep = "21210210";
      ret.Endereco = "";
      ret.Bairro = "";
      ret.Cidade = "";
      ret.NumeroResidencia = "";
      ret.Complemento = "";
      ret.listaNegra = false;
      ret.Telefone = "21988886492"
    }
    else {
      await c()
        .then(res => {
          ret = (res as UsuarioModel);
        })
        .catch(e => {
          ret.Sucesso = false;
          ret.Mensagem = "Erro desconhecido realizando esta operação. Tente novamente"
          console.log(e)
        })
        .finally(() => {
          //this.spinnerSrv.Hide();
        });
    }

    this.spinnerSrv.Hide();
    return ret;
  };

  async CarregarInformacoesEmpresa(): Promise<EmpresaModel> {
    let c = () => {
      let retorno: EmpresaModel = new EmpresaModel();

      return new Promise(async (resolve) => {
        // Monto o objeto que vou serializar no POST
        let p = {
          id: Global.IdentificacaoEmpresa,
          formaBusca: enFormaBusca.Individual.valueOf()
        }
        let jsonFinal: string = JSON.stringify(p);

        let url = Global.UrlServico + 'emp/obter/';
        let header = this.ObterHeaderHttp();
        let options = {
          headers: header
        };

        if (!Global.EhProducao) {
          console.log(header);
          console.log(options);
          console.log(jsonFinal);
        }

        let online = await this.VerificaServicoOnline();

        if (online.Sucesso) {
          await this.http.post(url, jsonFinal, options).subscribe(
            result => {
              if (!Global.EhProducao) {
                console.log('Sucesso!!!');
                console.log(result);
              }

              let r: any = result;
              if ((r.S) && (r.O)) {
                let objResp = JSON.parse(r.O);

                retorno.Sucesso = r.S;
                retorno.Mensagem = r.ME;
                retorno.Codigo = objResp.codigo;
                retorno.Nome = objResp.nome;
                retorno.Endereco = objResp.endereco;
                retorno.Bairro = objResp.bairro;
                retorno.Cidade = objResp.cidade;
                retorno.Telefone = objResp.telefone;
                retorno.Whatsapp = "55" + objResp.whatsapp;
                retorno.Mensagem1 = objResp.mensagem1;
                retorno.Mensagem2 = objResp.mensagem2;
                retorno.PermiteRetiradaNaLoja = objResp.permiteRetiradaNaLoja;
                retorno.PermiteEntregaEmCasa = objResp.permiteEntregaEmCasa;
                retorno.PermitePedidoSemLogin = objResp.permitePedidoSemLogin;
                retorno.PermiteItemEsgotado = objResp.permiteItemEsgotado;
                retorno.ExibeGrupoProdutos = objResp.exibeGrupoProdutos;
                retorno.ValorMinimoPedido = objResp.valorMinimoPedido;
                retorno.RedesSociaisEmail = objResp.redesSociaisEmail;
                retorno.RedesSociaisFacebook = objResp.redesSociaisFacebook;
                retorno.RedesSociaisInstagram = objResp.redesSociaisInstagram;
                retorno.RedesSociaisSite = objResp.redesSociaisSite;
                retorno.RedesSociaisTelefone = objResp.redesSociaisTelefone;
                retorno.RedesSociaisWhatsapp = objResp.redesSociaisWhatsapp;
                retorno.TipoValidacaoEntrega = objResp.tipoValidacaoEntrega;

                if (objResp.imagem) {
                  //p.PathImagem = Global.UrlFotos + e.imagem;
                  retorno.PathImagem = "data:image/jpeg;base64," + objResp.imagem;
                }
                else {
                  retorno.PathImagem = "../../../assets/noImage.png"
                }

                if (objResp.imagemFundo) {
                  retorno.PathImagemFundo = "data:image/jpeg;base64," + objResp.imagemFundo;
                }
              }
              else {
                retorno.Mensagem = Global.isNullOrEmpty(r.ME)
                  ? r.ME
                  : 'Nenhuma resposta válida do servidor';
              }

              resolve(retorno);
            },
            err => {
              //this.spinnerSrv.Hide();

              retorno.Mensagem = 'Não foi possível obter as informações desta empresa';

              resolve(retorno);
            });
        }
        else {
          retorno.Mensagem = online.Mensagem;
          resolve(retorno);
        }
      });
    };

    let ret: EmpresaModel = new EmpresaModel();
    if (Global.AmbienteMocado) {
      ret.Sucesso = true;
      ret.Mensagem = '';
      //ret.TempoMedioAtendimento = "N/I";
      ret.Codigo = 1;
      ret.Nome = 'EMPRESA MOCADO';
      ret.Endereco = 'Rua Professor Francisco Fonseca, 369';
      ret.Bairro = 'Bacaxá';
      ret.Cidade = 'Saquarema';
      ret.Telefone = '(21)98888-5555';
      ret.Whatsapp = '(21)98888-5555';
      //ret.HorarioAtendimento = 'De 15:00 às 21:00';
      ret.Mensagem1 = "Mensagem 1 - Cadastro de Empresas";
      ret.Mensagem2 = "Mensagem 2 de retorno do serviço";
      //ret.TaxaEntrega = 15.00;
      ret.PermiteRetiradaNaLoja = true;
      ret.PermiteEntregaEmCasa = true;
      ret.PermitePedidoSemLogin = false;
      ret.PathImagem = "../../../assets/noImage.png";
      ret.PathImagemFundo = "";
      ret.RedesSociaisEmail = "";
      ret.RedesSociaisFacebook = "";
      ret.RedesSociaisInstagram = "";
      ret.RedesSociaisSite = "";
      ret.RedesSociaisTelefone = "";
      ret.RedesSociaisWhatsapp = "";
      ret.TipoValidacaoEntrega = 0;
    }
    else {
      await c()
        .then(res => {
          ret = (res as EmpresaModel);
        })
        .catch(e => {
          ret.Sucesso = false;
          ret.Mensagem = "Erro desconhecido realizando esta operação. Tente novamente"
          console.log(e)
        });
    }
    return ret;
  }

  async CarregarInformacoesGrupoProduto(): Promise<GrupoModel> {
    let c = () => {
      let retorno: GrupoModel = new GrupoModel();

      return new Promise(async (resolve) => {
        // Monto o objeto que vou serializar no POST
        let p = {
          id: Global.IdentificacaoEmpresa
        }
        let jsonFinal: string = JSON.stringify(p);

        let url = Global.UrlServico + 'grp/obter/';
        let header = this.ObterHeaderHttp();
        let options = {
          headers: header
        };

        if (!Global.EhProducao) {
          console.log(header);
          console.log(options);
          console.log(jsonFinal);
        }

        let online = await this.VerificaServicoOnline();

        if (online.Sucesso) {
          await this.http.post(url, jsonFinal, options).subscribe(
            result => {
              if (!Global.EhProducao) {
                console.log('Sucesso!!!');
                console.log(result);
              }

              let r: any = result;
              if ((r.S) && (r.O)) {
                let objResp = JSON.parse(r.O);

                retorno.Sucesso = r.S;
                retorno.Mensagem = r.ME;
                retorno.Relacao = [];

                objResp.forEach(e => {
                  let p: DetalheGrupoModel = new DetalheGrupoModel();
                  p.Codigo = e.codigo;
                  p.Nome = e.nome;
                  p.PossuiDesconto = e.possuiDesconto;
                  p.CodigoNivel = e.codigoNivel;
                  p.CodigoGrupoNivelSuperior = e.codigoGrupoNivelSuperior;

                  if (e.imagem) {
                    //p.PathImagem = Global.UrlFotos + e.imagem;
                    p.PathImagem = "data:image/jpeg;base64," + e.imagem;
                  }
                  else {
                    p.PathImagem = "../../../assets/noImage.png"
                  }

                  retorno.Relacao.push(p);
                });
              }
              else {
                retorno.Mensagem = Global.isNullOrEmpty(r.ME)
                  ? r.ME
                  : 'Nenhuma resposta válida do servidor';
              }

              resolve(retorno);
            },
            err => {
              //this.spinnerSrv.Hide();

              retorno.Mensagem = 'Não foi possível obter a relação de grupos de produtos';

              resolve(retorno);
            });
        }
        else {
          retorno.Mensagem = online.Mensagem;
          resolve(retorno);
        }
      });
    };

    let ret: GrupoModel = new GrupoModel();
    if (Global.AmbienteMocado) {
      ret.Sucesso = true;
      ret.Mensagem = '';
      ret.Relacao = [];

      {
        let d: DetalheGrupoModel = new DetalheGrupoModel();
        d.Codigo = 1;
        d.Nome = "Nome do Grupo 1";
        d.PossuiDesconto = true;
        d.CodigoNivel = 0;
        d.CodigoGrupoNivelSuperior = null;
        d.PathImagem = '../../../assets/noImage.png';
        ret.Relacao.push(d);
      }

      {
        let d: DetalheGrupoModel = new DetalheGrupoModel();
        d.Codigo = 2;
        d.Nome = "Nome do Grupo 2";
        d.PossuiDesconto = false;
        d.CodigoNivel = 0;
        d.CodigoGrupoNivelSuperior = null;
        d.PathImagem = '../../../assets/noImage.png';
        ret.Relacao.push(d);
      }

      {
        let d: DetalheGrupoModel = new DetalheGrupoModel();
        d.Codigo = 3;
        d.Nome = "Nome do Grupo 3";
        d.PossuiDesconto = true;
        d.CodigoNivel = 0;
        d.CodigoGrupoNivelSuperior = null;
        d.PathImagem = '../../../assets/noImage.png';
        ret.Relacao.push(d);
      }

      {
        let d: DetalheGrupoModel = new DetalheGrupoModel();
        d.Codigo = 4;
        d.Nome = "Nome do Grupo 4";
        d.PossuiDesconto = true;
        d.CodigoNivel = 0;
        d.CodigoGrupoNivelSuperior = null;
        d.PathImagem = '../../../assets/noImage.png';
        ret.Relacao.push(d);
      }
    }
    else {
      await c()
        .then(res => {
          ret = (res as GrupoModel);
        })
        .catch(e => {
          ret.Sucesso = false;
          ret.Mensagem = "Erro desconhecido realizando esta operação. Tente novamente"
          console.log(e)
        });
    }
    return ret;
  }

  async CarregarInformacoesProdutos(pagina: number): Promise<ProdutoModel> {
    let c = () => {
      let retorno: ProdutoModel = new ProdutoModel();
      return new Promise(async (resolve) => {
        // Monto o objeto que vou serializar no POST
        let p = {
          id: Global.IdentificacaoEmpresa,
          pagina: pagina,
          cardapio: Global.EhCardapioVirtual
        }
        let jsonFinal: string = JSON.stringify(p);

        let url = Global.UrlServico + 'prod/obter/';
        let header = this.ObterHeaderHttp();
        let options = {
          headers: header
        };

        if (!Global.EhProducao) {
          console.log(header);
          console.log(options);
          console.log(jsonFinal);
        }

        let online = await this.VerificaServicoOnline();

        if (online.Sucesso) {
          await this.http.post(url, jsonFinal, options).subscribe(
            result => {
              if (!Global.EhProducao) {
                console.log('Sucesso!!!');
                console.log(result);
              }

              let r: any = result;

              Global.CarregamentoCompleto = (!r.O);

              if ((r.S) && (r.O)) {
                let objResp = JSON.parse(r.O);

                retorno.Sucesso = r.S;
                retorno.Mensagem = r.ME;
                retorno.Relacao = [];
                retorno.RelacaoCarrinho = [];

                objResp.forEach(e => {
                  let p: DetalheProdutoModel = new DetalheProdutoModel();
                  p.Codigo = e.codigoProduto;
                  p.CodigoGrupo = e.codigoGrupo
                  p.Nome = e.nome;
                  p.PossuiDesconto = e.possuiDesconto;
                  p.Descricao = e.descricao;
                  p.ValorNutricional = e.valorNutricional;
                  p.Avisos = e.avisos;
                  p.Preco = e.preco;
                  p.Quantidade = 1;
                  p.QuantidadeDisponivel = e.quantidadeDisponivel;
                  p.RelacaoGrupoAcompanhamento = [];

                  if (e.relacaoAcompanhamento) {
                    e.relacaoAcompanhamento.forEach(ac => {
                      let grupoAcompanhamento: DetalheGrupoAcompanhamentoModel = new DetalheGrupoAcompanhamentoModel();
                      grupoAcompanhamento.CodigoGrupoAcompanhamento = ac.codigoGrupoAcompanhamento;

                      p.RelacaoGrupoAcompanhamento.push(grupoAcompanhamento);
                    });
                  }

                  if (e.imagem) {
                    //p.PathImagem = Global.UrlFotos + e.imagem;
                    p.PathImagem = "data:image/jpeg;base64," + e.imagem;
                  }
                  else {
                    p.PathImagem = "../../../assets/carregando.gif"
                  }

                  retorno.Relacao.push(p);
                });
              }
              else {
                retorno.Mensagem = Global.isNullOrEmpty(r.ME)
                  ? r.ME
                  : 'Nenhuma resposta válida do servidor';
              }

              resolve(retorno);
            },
            err => {
              Global.CarregamentoCompleto = true;

              retorno.Mensagem = 'Não foi possível obter a relação de produtos';

              resolve(retorno);
            });
        }
        else {
          retorno.Mensagem = online.Mensagem;
          resolve(retorno);
        }
      });
    };

    let ret: ProdutoModel = new ProdutoModel();
    if (Global.AmbienteMocado) {
      ret.Sucesso = true;
      ret.Mensagem = '';
      ret.Relacao = [];
      ret.RelacaoCarrinho = [];

      {
        let d: DetalheProdutoModel = new DetalheProdutoModel();
        d.Codigo = 1;
        d.CodigoGrupo = 1;
        d.Nome = "Nome do Produto 1 Grupo 1";
        d.PossuiDesconto = true;
        d.Descricao = "Aqui tem a descrição do produto 1 grupo 1";
        d.ValorNutricional = "Isso aqui é gorduroso para um caralho";
        d.Avisos = "Se comer vai morrer";
        d.PathImagem = '../../../assets/noImage.png';
        d.Preco = 100.00;
        d.Quantidade = 0;
        d.QuantidadeDisponivel = 1;
        ret.Relacao.push(d);
      }

      {
        let d: DetalheProdutoModel = new DetalheProdutoModel();
        d.Codigo = 2;
        d.CodigoGrupo = 1;
        d.Nome = "Nome do Produto 2 Grupo 1";
        d.PossuiDesconto = false;
        d.Descricao = "Aqui tem a descrição do produto 2 grupo 1";
        d.ValorNutricional = "Isso aqui é salgado para um caralho";
        d.Avisos = "Se comer vai ter pressão alta!";
        d.PathImagem = '../../../assets/noImage.png';
        d.Preco = 200;
        d.QuantidadeDisponivel = 1;
        d.Quantidade = 0;
        ret.Relacao.push(d);
      }

      {
        let d: DetalheProdutoModel = new DetalheProdutoModel();
        d.Codigo = 3;
        d.CodigoGrupo = 2;
        d.Nome = "Nome do Produto 3 Grupo 2";
        d.PossuiDesconto = false;
        d.Descricao = "Aqui tem a descrição do produto 3 grupo 2";
        d.ValorNutricional = "Isso aqui é gostoso demais";
        d.Avisos = "Se comer vai dar bom";
        d.PathImagem = '../../../assets/noImage.png';
        d.Preco = 300.99;
        d.QuantidadeDisponivel = 1;
        d.Quantidade = 0;
        ret.Relacao.push(d);
      }

      {
        let d: DetalheProdutoModel = new DetalheProdutoModel();
        d.Codigo = 4;
        d.CodigoGrupo = 3;
        d.Nome = "Nome do Produto 4 Grupo 3";
        d.PossuiDesconto = false;
        d.Descricao = "Aqui tem a descrição do produto 4 grupo 3";
        d.ValorNutricional = "Isso aqui é gostoso demais";
        d.Avisos = "Se comer vai dar bom";
        d.PathImagem = '../../../assets/noImage.png';
        d.Preco = 300.1;
        d.QuantidadeDisponivel = 1;
        d.Quantidade = 0;
        ret.Relacao.push(d);
      }

      {
        let d: DetalheProdutoModel = new DetalheProdutoModel();
        d.Codigo = 5;
        d.CodigoGrupo = 3;
        d.Nome = "Nome do Produto 5 Grupo 3";
        d.PossuiDesconto = false;
        d.Descricao = "Aqui tem a descrição do produto 5 grupo 3";
        d.ValorNutricional = "Isso aqui é gostoso demais";
        d.Avisos = "Se comer vai dar bom";
        d.PathImagem = '../../../assets/noImage.png';
        d.Preco = 300.00;
        d.QuantidadeDisponivel = 1;
        d.Quantidade = 0;
        ret.Relacao.push(d);
      }

      {
        let d: DetalheProdutoModel = new DetalheProdutoModel();
        d.Codigo = 6;
        d.CodigoGrupo = 4;
        d.Nome = "Nome do Produto 6 Grupo 4";
        d.PossuiDesconto = false;
        d.Descricao = "Aqui tem a descrição do produto 6 grupo 4";
        d.ValorNutricional = "Isso aqui é gostoso demais";
        d.Avisos = "Se comer vai dar bom";
        d.PathImagem = '../../../assets/noImage.png';
        d.Preco = 0.98;
        d.QuantidadeDisponivel = 1;
        d.Quantidade = 0;
        ret.Relacao.push(d);
      }
    }
    else {
      await c()
        .then(res => { ret = (res as ProdutoModel); })
        .catch(e => {
          ret.Sucesso = false;
          ret.Mensagem = "Erro desconhecido realizando esta operação. Tente novamente"
          console.log(e)
        });
    }
    return ret;
  }

  async CarregarInformacoesImagensProdutos(pagina: number): Promise<ProdutoModel> {
    let c = () => {
      let retorno: ProdutoModel = new ProdutoModel();
      return new Promise(async (resolve) => {
        // Monto o objeto que vou serializar no POST
        let p = {
          id: Global.IdentificacaoEmpresa,
          pagina: pagina,
          cardapio: Global.EhCardapioVirtual
        }
        let jsonFinal: string = JSON.stringify(p);

        let url = Global.UrlServico + 'prod/obterImagens/';
        let header = this.ObterHeaderHttp();
        let options = {
          headers: header
        };

        if (!Global.EhProducao) {
          console.log(header);
          console.log(options);
          console.log(jsonFinal);
        }

        let online = await this.VerificaServicoOnline();

        if (online.Sucesso) {
          await this.http.post(url, jsonFinal, options).subscribe(
            result => {
              if (!Global.EhProducao) {
                console.log('Sucesso!!!');
                console.log(result);
              }

              let r: any = result;

              Global.CarregamentoCompletoImagem = (!r.O);

              if ((r.S) && (r.O)) {
                let objResp = JSON.parse(r.O);

                retorno.Sucesso = r.S;
                retorno.Mensagem = r.ME;
                retorno.Relacao = [];
                retorno.RelacaoCarrinho = [];

                objResp.forEach(e => {
                  let p: DetalheProdutoModel = new DetalheProdutoModel();
                  p.Codigo = e.codigoProduto;

                  if (e.imagem) {
                    p.PathImagem = "data:image/jpeg;base64," + e.imagem;
                  }
                  else {
                    p.PathImagem = "../../../assets/noImage.png"
                  }

                  retorno.Relacao.push(p);
                });
              }
              else {
                retorno.Mensagem = Global.isNullOrEmpty(r.ME)
                  ? r.ME
                  : 'Nenhuma resposta válida do servidor';
              }

              resolve(retorno);
            },
            err => {
              Global.CarregamentoCompletoImagem = true;

              retorno.Mensagem = 'Não foi possível obter a relação de imagens de produtos';

              resolve(retorno);
            });
        }
        else {
          retorno.Mensagem = online.Mensagem;
          resolve(retorno);
        }
      });
    };

    let ret: ProdutoModel = new ProdutoModel();
    await c()
      .then(res => { ret = (res as ProdutoModel); })
      .catch(e => {
        ret.Sucesso = false;
        ret.Mensagem = "Erro desconhecido realizando esta operação. Tente novamente"
        console.log(e)
      });

    return ret;
  }

  async CarregarInformacoesFormaPagamento(): Promise<FormaPagamentoModel> {
    let c = () => {
      let retorno: FormaPagamentoModel = new FormaPagamentoModel();

      return new Promise(async (resolve) => {
        // Monto o objeto que vou serializar no POST
        let p = {
          id: Global.IdentificacaoEmpresa
        }
        let jsonFinal: string = JSON.stringify(p);

        let url = Global.UrlServico + 'carr/fPag/';
        let header = this.ObterHeaderHttp();
        let options = {
          headers: header
        };

        if (!Global.EhProducao) {
          console.log(header);
          console.log(options);
          console.log(jsonFinal);
        }

        let online = await this.VerificaServicoOnline();

        if (online.Sucesso) {
          await this.http.post(url, jsonFinal, options).subscribe(
            result => {
              if (!Global.EhProducao) {
                console.log('Sucesso!!!');
                console.log(result);
              }

              let r: any = result;
              if ((r.S) && (r.O)) {
                let objResp = JSON.parse(r.O);

                retorno.Sucesso = r.S;
                retorno.Mensagem = r.ME;
                retorno.Relacao = [];

                objResp.forEach(e => {
                  let p: DetalheFormaPagamentoModel = new DetalheFormaPagamentoModel();
                  p.Codigo = e.codigo;
                  p.Nome = e.nome;
                  p.PermiteTroco = e.permiteTroco;
                  p.Selecionado = false;

                  retorno.Relacao.push(p);
                });
              }
              else {
                retorno.Mensagem = Global.isNullOrEmpty(r.ME)
                  ? r.ME
                  : 'Nenhuma resposta válida do servidor';
              }

              resolve(retorno);
            },
            err => {
              //this.spinnerSrv.Hide();

              retorno.Mensagem = 'Não foi possível obter a relação de formas de pagamento';

              resolve(retorno);
            });
        }
        else {
          retorno.Mensagem = online.Mensagem;
          resolve(retorno);
        }
      });
    };

    let ret: FormaPagamentoModel = new FormaPagamentoModel();
    if (Global.AmbienteMocado) {
      ret.Sucesso = true;
      ret.Mensagem = '';
      ret.Relacao = [];

      {
        let d: DetalheFormaPagamentoModel = new DetalheFormaPagamentoModel();
        d.Codigo = 1;
        d.Nome = "Dinheiro";
        d.PermiteTroco = true;
        d.Selecionado = false;
        ret.Relacao.push(d);
      }

      {
        let d: DetalheFormaPagamentoModel = new DetalheFormaPagamentoModel();
        d.Codigo = 2;
        d.Nome = "Cartão Crédito";
        d.PermiteTroco = false;
        d.Selecionado = false;
        ret.Relacao.push(d);
      }

      {
        let d: DetalheFormaPagamentoModel = new DetalheFormaPagamentoModel();
        d.Codigo = 3;
        d.Nome = "Cartão Débito";
        d.PermiteTroco = false;
        d.Selecionado = false;
        ret.Relacao.push(d);
      }

      {
        let d: DetalheFormaPagamentoModel = new DetalheFormaPagamentoModel();
        d.Codigo = 4;
        d.Nome = "Vale Fiado";
        d.PermiteTroco = false;
        d.Selecionado = false;
        ret.Relacao.push(d);
      }
    }
    else {
      await c()
        .then(res => {
          ret = (res as FormaPagamentoModel);
        })
        .catch(e => {
          ret.Sucesso = false;
          ret.Mensagem = "Erro desconhecido realizando esta operação. Tente novamente"
          console.log(e)
        });
    }
    return ret;
  }

  async SincronizarCarrinhoCompra(usuario: UsuarioModel,
    carrinho: CarrinhoModel,
    relacaoProdutos: DetalheProdutoModel[],
    relacaoAcompanhamento: DetalheAcompanhamentoModel[]): Promise<RetornoModel> {
    let c = () => {
      let retorno: RetornoModel = new RetornoModel();

      return new Promise(async (resolve) => {
        if ((carrinho) && (relacaoProdutos)) {
          let relacaoAcompanhamntoDto: any[] = [];
          relacaoAcompanhamento.forEach(e => {
            if (e.QuantidadeSelecionada > 0) {
              let p = {
                acompanhamento: e.Acompanhamento,
                quantidade: e.QuantidadeSelecionada,
                valor: e.ValorAcompanhamento
              }

              relacaoAcompanhamntoDto.push(p);
            }
          });

          let relacaoProdutosDto: any[] = [];
          relacaoProdutos.forEach(e => {
            let p = {
              id: Global.IdentificacaoEmpresa,
              codigo: e.Codigo,
              observacao: e.ObservacaoNoPedido,
              quantidade: e.Quantidade,
              valorUnitario: e.Preco,
              relacaoAcompanhamento: []
            };

            if ((e.RelacaoGrupoAcompanhamento) && (e.RelacaoGrupoAcompanhamento.length > 0) && (e.RelacaoAcompanhamentoCarrinho)) {
              let maiorValor = 0;
              let valorMedio = 0;
              let valorDizima = 0;
              e.RelacaoAcompanhamentoCarrinho.forEach(x => {
                x.RelacaoAcompanhamento.forEach(y => {
                  if (x.MaiorValor)
                    maiorValor = Math.max.apply(null,
                      x.RelacaoAcompanhamento.map(function (o) { return o.Selecionado ? o.ValorAcompanhamento : 0; }));
                  if (x.Pizza) {
                    if (x.MaiorValor) {
                      maiorValor = Math.max.apply(null,
                        x.RelacaoAcompanhamento.map(function (o) { return o.Selecionado ? o.ValorAcompanhamento : 0; }));
                      valorMedio = (maiorValor / x.QuantidadeMaximaGrupo);
                      valorDizima = maiorValor - (valorMedio * x.QuantidadeMaximaGrupo);
                    }
                    else {
                      x.RelacaoAcompanhamento.forEach(z => {
                        if (z.Selecionado) {
                          valorMedio += ((z.ValorAcompanhamento / x.QuantidadeMaximaGrupo) * z.QuantidadeSelecionada);
                        }
                      });
                      valorMedio = valorMedio / x.QuantidadeMaximaGrupo;

                      x.RelacaoAcompanhamento.forEach(z => {
                        if (z.Selecionado) {
                          maiorValor += ((z.ValorAcompanhamento / x.QuantidadeMaximaGrupo) * z.QuantidadeSelecionada);
                        }
                      });

                      valorDizima = (maiorValor - (valorMedio * x.QuantidadeMaximaGrupo));
                    }
                  }

                  if (y.Selecionado) {
                    if (valorDizima > 0) {
                      // Faço o ajuste da dízima
                      if ((y.ValorAcompanhamento - valorDizima) <= 0) {
                        // A dízima torna o valor zero ou negativo e por isso faço o devido ajuste
                        valorDizima -= (y.ValorAcompanhamento - 0.01);
                        valorMedio = 0.01;
                      }
                      else {
                        let valorUnitario = y.ValorAcompanhamento;
                        if ((y.QuantidadeSelecionada > 0) && (valorDizima % y.QuantidadeSelecionada == 0)) {
                          // Posso descarregar a dízima normalmente
                          valorMedio = valorUnitario + valorDizima / y.QuantidadeSelecionada;
                          valorDizima = 0;
                        }
                        else if (y.QuantidadeSelecionada == 1) {
                          // Posso descarregar a dízima normalmente
                          valorMedio = valorUnitario + valorDizima;
                          valorDizima = 0;
                        }
                      }
                    }

                    let valorAcompanhamento = 0;
                    if (x.Pizza) {
                      valorAcompanhamento = valorMedio;
                    }
                    else {
                      valorAcompanhamento = x.MaiorValor ? maiorValor : y.ValorAcompanhamento;
                    }
    
                    let acomp = {
                      nome: x.NomeGrupo,
                      acompanhamento: y.Acompanhamento,
                      quantidade: y.QuantidadeSelecionada,
                      valorAcompanhamento: valorAcompanhamento,
                      codigoProdutoAcompanhamento: y.CodigoProdutoAcompanhamento,
                      quantidadeProdutoAcompanhamento: y.QuantidadeProdutoAcompanhamento
                    }

                    p.relacaoAcompanhamento.push(acomp);
                  }
                });
              });
            }

            relacaoProdutosDto.push(p);
          });

          let carrinhoDto = {
            id: Global.IdentificacaoEmpresa,
            codigoCliente: usuario ? usuario.Codigo : null,
            codigoFormaPagamento: carrinho.CodigoFormaPagamento,
            retornarTroco: carrinho.RetornarTroco,
            valorTaxaEntrega: carrinho.TaxaEntrega,
            valorPagamento: carrinho.ValorPagamento,
            valorTotal: carrinho.ValorTotal,
            localEntrega: carrinho.LocalEntrega,
            cep: carrinho.Cep,
            endereco: carrinho.Endereco,
            bairro: carrinho.Bairro,
            cidade: carrinho.Cidade,
            numeroResidencia: carrinho.NumeroResidencia,
            complemento: carrinho.Complemento,
            cpf: carrinho.CPF,
            observacao: carrinho.Observacao,
            nome: carrinho.Nome,
            telefone: carrinho.Telefone,
            relacaoProdutos: relacaoProdutosDto,
            relacaoAcompanhamento: relacaoAcompanhamntoDto,
            codigoCupom: carrinho.CodigoCupomDesconto,
            valorDesconto: carrinho.ValorDesconto
          };

          let jsonFinal: string = JSON.stringify(carrinhoDto);
          let url = Global.UrlServico + 'carr/sinc/';
          let header = this.ObterHeaderHttp();

          let options = {
            headers: header
          };

          if (!Global.EhProducao) {
            console.log(header);
            console.log(options);
            console.log(jsonFinal);
          }

          try {
            //let online = await this.VerificaServicoOnline();

            //if (online.Sucesso) {
            this.spinnerSrv.Show('Sincronizando carrinho de compras...', 60000);

            await this.http.post(url, jsonFinal, options).subscribe(
              result => {
                this.spinnerSrv.Hide();

                if (!Global.EhProducao) {
                  console.log('Sucesso!!!');
                  console.log(result);
                }

                let r: any = result;
                retorno.Sucesso = r.S;
                retorno.Mensagem = r.M;

                if ((r.S) && (r.O)) {
                  let objResp = JSON.parse(r.O);

                  retorno.Objeto = {
                    CodigoCarrinho: objResp.codigoCarrinho,
                    TempoAtendimento: objResp.tempoAtendimento
                  };
                }

                resolve(retorno);
              },
              err => {
                this.spinnerSrv.Hide();

                retorno.Mensagem = 'Não foi possível sincronizar este carrinho de compras. Tente novamente!';
                retorno.Erro = err;

                //this.alertSrv.Mensagem(retorno.Mensagem);
                resolve(retorno);
              });
            // }
            // else {
            //   retorno.Mensagem = online.Mensagem;
            //   resolve(retorno);
            // }
          }
          catch
          {
            retorno.Sucesso = false;
            retorno.Mensagem = 'Não foi possível sincronizar este carrinho de compras. Tente novamente!';
          }
        }
        else {
          //this.spinnerSrv.Hide();
          retorno.Mensagem = 'Nenhuma informação de carrinho de compras foi encontrado para sincronização';
          resolve(retorno);
        }
      });
    };

    let ret: RetornoModel = new RetornoModel();
    if (Global.AmbienteMocado) {
      ret.Sucesso = true;
      ret.Mensagem = "Pedido registrado com sucesso!";
      ret.Objeto = {
        CodigoCarrinho: 2176355,
        TempoAtendimento: "Entre 30 e 50 minutos"
      };
    }
    else {
      await c()
        .then(res => {
          ret = (res as RetornoModel);
        })
        .catch(e => {
          ret.Sucesso = false;
          ret.Mensagem = "Erro desconhecido realizando esta operação. Tente novamente"
          console.log(e)
        })
        .finally(() => {
          this.spinnerSrv.Hide();
        });
    }

    //this.spinnerSrv.Hide();
    return ret;
  }

  async AtualizarRelacaoPedidos(codigoCliente: number): Promise<PedidoModel> {
    let c = () => {
      let retorno: PedidoModel = new PedidoModel();

      return new Promise(async (resolve) => {
        // Monto o objeto que vou serializar no POST
        let p = {
          id: Global.IdentificacaoEmpresa,
          codigoCliente: codigoCliente
        }
        let jsonFinal: string = JSON.stringify(p);

        let url = Global.UrlServico + 'carr/relPed/';
        let header = this.ObterHeaderHttp();
        let options = {
          headers: header
        };

        if (!Global.EhProducao) {
          console.log(header);
          console.log(options);
          console.log(jsonFinal);
        }

        let online = await this.VerificaServicoOnline();

        if (online.Sucesso) {
          this.spinnerSrv.Show("Carregando informações de pedidos já realizados");

          await this.http.post(url, jsonFinal, options).subscribe(
            result => {
              //this.spinnerSrv.Hide();

              if (!Global.EhProducao) {
                console.log('Sucesso!!!');
                console.log(result);
              }

              let r: any = result;
              if ((r.S) && (r.O)) {
                let objResp = JSON.parse(r.O);

                retorno.Sucesso = r.S;
                retorno.Mensagem = r.ME;
                retorno.Relacao = [];

                objResp.forEach(e => {
                  let p: DetalhePedidoModel = new DetalhePedidoModel();
                  p.DataCriacao = e.dataCriacao;
                  p.ValorTotal = e.valorTotal;
                  p.Situacao = e.situacao;
                  p.Codigo = e.codigo;
                  p.MotivoCancelamento = e.motivoCancelamento;

                  retorno.Relacao.push(p);
                });
              }
              else {
                //this.spinnerSrv.Hide();

                retorno.Mensagem = Global.isNullOrEmpty(r.ME)
                  ? r.ME
                  : 'Nenhuma resposta válida do servidor';
              }

              resolve(retorno);
            },
            err => {
              //this.spinnerSrv.Hide();

              retorno.Mensagem = 'Não foi possível obter a relação de pedidos do cliente';
              resolve(retorno);
            });
        }
        else {
          //this.spinnerSrv.Hide();

          retorno.Mensagem = online.Mensagem;
          resolve(retorno);
        }
      });
    };

    let ret: PedidoModel = new PedidoModel();
    if (Global.AmbienteMocado) {
      ret.Sucesso = true;
      ret.Mensagem = '';
      ret.Relacao = [];

      {
        let d: DetalhePedidoModel = new DetalhePedidoModel();
        d.Codigo = "1";
        d.DataCriacao = new Date(2020, 1, 5);
        d.ValorTotal = Math.random();
        d.Situacao = "Pedido Entregue";
        ret.Relacao.push(d);
      }

      {
        let d: DetalhePedidoModel = new DetalhePedidoModel();
        d.Codigo = "2";
        d.DataCriacao = new Date(2020, 1, 7);
        d.ValorTotal = Math.random();
        d.Situacao = "Pedido Cancelado";
        ret.Relacao.push(d);
      }

      {
        let d: DetalhePedidoModel = new DetalhePedidoModel();
        d.Codigo = "3";
        d.DataCriacao = new Date(2020, 1, 12);
        d.ValorTotal = 50.00;
        d.Situacao = "Pedido Criado";
        ret.Relacao.push(d);
      }

      {
        let d: DetalhePedidoModel = new DetalhePedidoModel();
        d.Codigo = "4";
        d.DataCriacao = new Date(2020, 1, 15);
        d.ValorTotal = 12550.00;
        d.Situacao = "Pedido Saiu Para Entrega";
        ret.Relacao.push(d);
      }
    }
    else {
      await c()
        .then(res => {
          ret = (res as PedidoModel);
        })
        .catch(e => {
          ret.Sucesso = false;
          ret.Mensagem = "Erro desconhecido realizando esta operação. Tente novamente"
          console.log(e)
        })
        .finally(() => {
          this.spinnerSrv.Hide();
        });
    }

    //this.spinnerSrv.Hide();
    return ret;
  }

  async RegistrarNovoUsuario(login: string,
    senha: string,
    confirmeSenha: string,
    nome: string,
    cep: string,
    endereco: string,
    bairro: string,
    cidade: string,
    numeroEndereco: string,
    complemento: string,
    telefone: string): Promise<RetornoModel> {
    let c = () => {
      let retorno: RetornoModel = new RetornoModel();

      return new Promise(async (resolve) => {
        if ((telefone) &&
          (senha) &&
          //(cep) &&
          (numeroEndereco)) {

          let contaDto = {
            id: Global.IdentificacaoEmpresa,
            login: login,
            senha: senha,
            confirmeSenha: confirmeSenha,
            nome: nome,
            cep: cep,
            endereco: endereco,
            bairro: bairro,
            cidade: cidade,
            numeroResidencia: numeroEndereco,
            complemento: complemento,
            telefone: telefone
          };

          let jsonFinal: string = JSON.stringify(contaDto);
          let url = Global.UrlServico + 'aut/criar/';
          let header = this.ObterHeaderHttp();

          let options = {
            headers: header
          };

          if (!Global.EhProducao) {
            console.log(header);
            console.log(options);
            console.log(jsonFinal);
          }

          let online = await this.VerificaServicoOnline();

          if (online.Sucesso) {
            this.spinnerSrv.Show('Cadastrando cliente...');

            await this.http.post(url, jsonFinal, options).subscribe(
              result => {
                //this.spinnerSrv.Hide();

                if (!Global.EhProducao) {
                  console.log('Sucesso!!!');
                  console.log(result);
                }

                let r: any = result;

                if (r.S) {
                  retorno.Sucesso = r.S;
                  retorno.Mensagem = r.M;
                }
                else {
                  retorno.Sucesso = false;
                  retorno.Mensagem = "";
                  if (r.M) {
                    retorno.Mensagem += r.M;
                  }

                  if (r.errors) {
                    for (let i = 0; i < r.errors.length; i++) {
                      retorno.Mensagem += r.errors[i] + "\n";
                    }
                  }
                }

                resolve(retorno);
              },
              err => {
                //this.spinnerSrv.Hide();

                retorno.Mensagem = 'Não foi possível criar este cliente. Tente novamente!';
                retorno.Erro = err;

                //this.alertSrv.Mensagem(retorno.Mensagem);
                resolve(retorno);
              });
          }
          else {
            //this.spinnerSrv.Hide();

            retorno.Mensagem = online.Mensagem;
            resolve(retorno);
          }
        }
        else {
          //this.spinnerSrv.Hide();
          retorno.Mensagem = 'Nenhuma informação de criação de conta foi fornecido';
          resolve(retorno);
        }
      });
    };

    let ret: RetornoModel = new RetornoModel();
    if (Global.AmbienteMocado) {
      ret.Sucesso = true;
      ret.Mensagem = "Cliente cadastrado com sucesso. Realize login para ativar sua conta."
    }
    else {
      await c()
        .then(res => {
          ret = (res as RetornoModel);
        })
        .catch(e => {
          ret.Sucesso = false;
          ret.Mensagem = "Erro desconhecido realizando esta operação. Tente novamente"
          console.log(e)
        })
        .finally(() => {
          this.spinnerSrv.Hide();
        });
    }

    //this.spinnerSrv.Hide();
    return ret;
  }

  async ObterInformacoesFuncionamentoEmpresa(): Promise<FuncionamentoModel> {
    let c = () => {
      let retorno: FuncionamentoModel = new FuncionamentoModel();

      return new Promise(async (resolve) => {
        let p = {
          id: Global.IdentificacaoEmpresa,
          formaBusca: enFormaBusca.Individual.valueOf()
        };

        let jsonFinal: string = JSON.stringify(p);
        let url = Global.UrlServico + 'emp/func/';
        let header = this.ObterHeaderHttp();

        let options = {
          headers: header
        };

        let online = await this.VerificaServicoOnline();

        if (online.Sucesso) {
          await this.http.post(url, jsonFinal, options).subscribe(
            result => {
              if (!Global.EhProducao) {
                console.log('Sucesso!!!');
                console.log(result);
              }

              let r: any = result;
              retorno.Sucesso = r.S;
              retorno.Mensagem = r.M;

              if ((r.S) && (r.O)) {
                let objResp = JSON.parse(r.O);

                retorno.TempoMedioAtendimento = objResp.tempoMedioAtendimento;
                retorno.HorarioAtendimento = objResp.horarioAtendimento;
                retorno.TaxaEntrega = objResp.taxaEntrega;
                retorno.EmFuncionamento = objResp.emFuncionamento;
              }

              resolve(retorno);
            },
            err => {
              retorno.Sucesso = false;
              retorno.Mensagem = 'Não foi possível carregar informações de funcionamento da empresa';

              resolve(retorno);
            });
        }
        else {
          retorno.Mensagem = online.Mensagem;
          resolve(retorno);
        }
      });
    };

    let ret: FuncionamentoModel = new FuncionamentoModel();
    if (Global.AmbienteMocado) {
      ret.Sucesso = true;
      ret.Mensagem = "";
      ret.TempoMedioAtendimento = "45 minutos";
      ret.HorarioAtendimento = "De 15:00 às 21:00";
      ret.TaxaEntrega = 17;
      ret.EmFuncionamento = true;
    }
    else {
      await c()
        .then(res => {
          ret = (res as FuncionamentoModel);
        })
        .catch(e => {
          ret.Sucesso = false;
          ret.Mensagem = "Erro desconhecido realizando esta operação. Tente novamente"
          console.log(e)
        });
    }

    return ret;
  }

  async ValidarDisponibilidadeEntregaNoCep(cep: string, validaFaixa: boolean): Promise<RetornoModel> {
    let c = () => {
      let retorno: RetornoModel = new RetornoModel();

      return new Promise(async (resolve) => {
        let p = {
          id: Global.IdentificacaoEmpresa,
          cep: cep,
          validaFaixa: validaFaixa
        };

        let jsonFinal: string = JSON.stringify(p);
        let url = Global.UrlServico + 'emp/cep/';
        let header = this.ObterHeaderHttp();

        let options = {
          headers: header
        };

        let online = await this.VerificaServicoOnline();

        if (online.Sucesso) {
          this.spinnerSrv.Show("Validando CEP...");

          await this.http.post(url, jsonFinal, options).subscribe(
            result => {
              //this.spinnerSrv.Hide();
              if (!Global.EhProducao) {
                console.log('Sucesso!!!');
                console.log(result);
              }

              let r: any = result;
              retorno.Sucesso = r.S;
              retorno.Mensagem = r.M;

              if (r.errors) {
                retorno.Sucesso = false;
                retorno.Mensagem = "";
                r.errors.forEach(e => {
                  retorno.Mensagem += e + "\n";
                });
              }

              if ((r.S) && (r.O)) {
                let objResp = JSON.parse(r.O);

                retorno.Objeto = {
                  Cep: objResp.cep,
                  Endereco: objResp.endereco,
                  Bairro: objResp.bairro,
                  Cidade: objResp.cidade,
                  Localizado: objResp.localizado,
                  TaxaEntrega: objResp.taxaEntrega
                }
              }

              resolve(retorno);
            },
            err => {
              //this.spinnerSrv.Hide();
              retorno.Sucesso = false;
              retorno.Mensagem = 'Não foi possível carregar informações de área de entrega';

              resolve(retorno);
            });
        }
        else {
          //this.spinnerSrv.Hide();
          retorno.Mensagem = online.Mensagem;
          resolve(retorno);
        }
      });
    };

    let ret: RetornoModel = new RetornoModel();
    if (Global.AmbienteMocado) {
      ret.Sucesso = true;
      ret.Mensagem = "";
      ret.Objeto = {
        Cep: cep,
        Endereco: "Rua das Pedras de Areia Cristalina",
        Bairro: "Porto da Roça",
        Cidade: "SaquaremaCity",
        TaxaEntrega: 3
      };
    }
    else {
      await c().then(res => { ret = (res as RetornoModel); })
        .catch(e => {
          ret.Sucesso = false;
          ret.Mensagem = "Erro desconhecido realizando esta operação. Tente novamente"
          console.log(e)
        })
        .finally(() => {
          this.spinnerSrv.Hide();
        });
    }

    //this.spinnerSrv.Hide();
    return ret;
  }

  async CarregarBairrosEntregaEmpresa(): Promise<RetornoModel> {
    let c = () => {
      let retorno: RetornoModel = new RetornoModel();

      return new Promise(async (resolve) => {
        let p = {
          id: Global.IdentificacaoEmpresa
        };

        let jsonFinal: string = JSON.stringify(p);
        let url = Global.UrlServico + 'emp/bairros/';
        let header = this.ObterHeaderHttp();

        let options = {
          headers: header
        };

        let online = await this.VerificaServicoOnline();

        if (online.Sucesso) {
          //this.spinnerSrv.Show("Carregando Bairros...");

          await this.http.post(url, jsonFinal, options).subscribe(
            result => {
              //this.spinnerSrv.Hide();
              if (!Global.EhProducao) {
                console.log('Sucesso!!!');
                console.log(result);
              }

              let r: any = result;
              retorno.Sucesso = r.S;
              retorno.Mensagem = r.M;

              if (r.errors) {
                retorno.Sucesso = false;
                retorno.Mensagem = "";
                r.errors.forEach(e => {
                  retorno.Mensagem += e + "\n";
                });
              }

              if ((r.S) && (r.O)) {
                let objResp = JSON.parse(r.O);

                retorno.Objeto = {
                  ListaTaxaEntrega: objResp.listaTaxaEntrega
                }
              }

              resolve(retorno);
            },
            err => {
              //this.spinnerSrv.Hide();
              retorno.Sucesso = false;
              retorno.Mensagem = 'Não foi possível carregar informações de área de entrega';

              resolve(retorno);
            });
        }
        else {
          //this.spinnerSrv.Hide();
          retorno.Mensagem = online.Mensagem;
          resolve(retorno);
        }
      });
    };

    let ret: RetornoModel = new RetornoModel();

    await c().then(res => { ret = (res as RetornoModel); })
      .catch(e => {
        ret.Sucesso = false;
        ret.Mensagem = "Erro desconhecido realizando esta operação. Tente novamente"
        console.log(e)
      });
    // .finally(() => {
    //   this.spinnerSrv.Hide();
    // });

    return ret;
  }

  async ValidarDisponibilidadeEntregaLatLng(latitude: any, longitude: any): Promise<RetornoModel> {
    let c = () => {
      let retorno: RetornoModel = new RetornoModel();

      return new Promise(async (resolve) => {
        let p = {
          id: Global.IdentificacaoEmpresa,
          latitude: latitude,
          longitude: longitude
        };

        let jsonFinal: string = JSON.stringify(p);
        let url = Global.UrlServico + 'emp/latlng/';
        let header = this.ObterHeaderHttp();

        let options = {
          headers: header
        };

        let online = await this.VerificaServicoOnline();

        if (online.Sucesso) {
          this.spinnerSrv.Show("Consultando Área de Entrega...");

          await this.http.post(url, jsonFinal, options).subscribe(
            result => {
              if (!Global.EhProducao) {
                console.log('Sucesso!!!');
                console.log(result);
              }

              let r: any = result;
              retorno.Sucesso = r.S;
              retorno.Mensagem = r.M;

              if (r.errors) {
                retorno.Sucesso = false;
                retorno.Mensagem = "";
                r.errors.forEach(e => {
                  retorno.Mensagem += e + "\n";
                });
              }

              if ((r.S) && (r.O)) {
                let objResp = JSON.parse(r.O);

                retorno.Objeto = {
                  TaxaEntrega: objResp.taxaEntrega
                }
              }

              resolve(retorno);
            },
            err => {
              retorno.Sucesso = false;
              retorno.Mensagem = 'Não foi possível carregar informações de área de entrega';

              resolve(retorno);
            });
        }
        else {
          retorno.Mensagem = online.Mensagem;
          resolve(retorno);
        }
      });
    };

    let ret: RetornoModel = new RetornoModel();
    if (Global.AmbienteMocado) {
      ret.Sucesso = true;
      ret.Mensagem = "";
      ret.Objeto = {
        TaxaEntrega: 3
      };
    }
    else {
      await c().then(res => { ret = (res as RetornoModel); })
        .catch(e => {
          ret.Sucesso = false;
          ret.Mensagem = "Erro desconhecido realizando esta operação. Tente novamente"
          console.log(e)
        })
        .finally(() => {
          this.spinnerSrv.Hide();
        });
    }

    return ret;
  }

  async CarregarInformacoesAcompanhamentoPedido(): Promise<AcompanhamentoModel> {
    let c = () => {
      let retorno: AcompanhamentoModel = new AcompanhamentoModel();

      return new Promise(async (resolve) => {
        let p = {
          id: Global.IdentificacaoEmpresa
        };

        let jsonFinal: string = JSON.stringify(p);
        let url = Global.UrlServico + 'carr/acomp/';
        let header = this.ObterHeaderHttp();

        let options = {
          headers: header
        };

        let online = await this.VerificaServicoOnline();

        if (online.Sucesso) {
          await this.http.post(url, jsonFinal, options).subscribe(
            result => {
              if (!Global.EhProducao) {
                console.log('Sucesso!!!');
                console.log(result);
              }

              let r: any = result;
              retorno.Sucesso = r.S;
              retorno.Mensagem = r.M;
              retorno.Relacao = [];

              if ((r.S) && (r.O)) {
                let objResp = JSON.parse(r.O);

                objResp.forEach(e => {
                  let p: DetalheAcompanhamentoModel = new DetalheAcompanhamentoModel();
                  p.CodigoGrupoAcompanhamento = e.codigoGrupoAcompanhamento;
                  p.NomeGrupoAcompanhamento = e.nomeGrupoAcompanhamento;
                  p.Codigo = e.codigo;
                  p.Acompanhamento = e.acompanhamento;
                  p.QuantidadeMaximaAcompanhamento = e.quantidadeMaximaAcompanhamento;
                  p.ValorAcompanhamento = e.valorAcompanhamento;
                  p.QuantidadeMaximaGrupo = e.quantidadeMaximaGrupo;
                  p.Obrigatorio = e.obrigatorio;
                  p.TipoAcompanhamento = (e.tipoAcompanhamento as enTipoAcompanhamento);
                  p.QuantidadeSelecionada = 0;
                  p.MaiorValor = e.maiorValor;
                  p.Pizza = e.pizza;

                  if (e.relacaoProdutoAcompanhamento) {
                    p.RelacaoProdutoAcompanhamento = [];
                    e.relacaoProdutoAcompanhamento.forEach(a => {
                      let x: AcompanhamentoPedidoProdutoModel = new AcompanhamentoPedidoProdutoModel();
                      x.CodigoProduto = a.CodigoProduto;
                      x.CodigoGrupoAcompanhamento = a.CodigoGrupoAcompanhamento;
                      x.Codigo = a.Codigo;
                      x.CodigoProdutoAcompanhamento = a.CodigoProdutoAcompanhamento;
                      x.QuantidadeProdutoAcompanhamento = a.QuantidadeProdutoAcompanhamento;

                      p.RelacaoProdutoAcompanhamento.push(x);
                    });
                  }
                  retorno.Relacao.push(p);
                });
              }

              resolve(retorno);
            },
            err => {
              retorno.Sucesso = false;
              retorno.Mensagem = 'Não foi possível carregar informações de acompanhamento do pedido da empresa';

              resolve(retorno);
            });
        }
        else {
          retorno.Mensagem = online.Mensagem;
          resolve(retorno);
        }
      });
    };

    let ret: AcompanhamentoModel = new AcompanhamentoModel();
    if (Global.AmbienteMocado) {
      ret.Sucesso = true;
      ret.Mensagem = "";
      ret.Relacao = [];

      {
        let p: DetalheAcompanhamentoModel = new DetalheAcompanhamentoModel();
        p.Codigo = 1;
        p.Acompanhamento = "Guadanapo";
        p.QuantidadeMaximaAcompanhamento = 5;
        p.QuantidadeSelecionada = 0;

        ret.Relacao.push(p);
      }

      {
        let p: DetalheAcompanhamentoModel = new DetalheAcompanhamentoModel();
        p.Codigo = 2;
        p.Acompanhamento = "Ketchup";
        p.QuantidadeMaximaAcompanhamento = 10;
        p.QuantidadeSelecionada = 0;

        ret.Relacao.push(p);
      }

      {
        let p: DetalheAcompanhamentoModel = new DetalheAcompanhamentoModel();
        p.Codigo = 31;
        p.Acompanhamento = "ACOMPANHAMENTO COM TODOS OS ESPAÇOS PREENCHIDOS HHHHHHHHHHHHHHHHHHHHHHHHHH HHHHHHHHHHHHHHHHHH H HHHHHHHHHHHHHH H H H HHHHHHHHHHHHHHHHHHHHHH H HHHHHHHHHHHHHHHHHHH HHHHHHHHHHHHHH HHHHHHHHHHH HHHHHHHHH HHHHHHHHHHHHHHHHHHHHHHHHHHHH H HHHHHHHHHHHHHHHHHHHHH H                       HHHHHHHHHHHHHHHHHHHHHHHHHHHHHH HHHHHHHHHHHHHHHHHH HHHHHHHHHHHHHHHHHHHHHH H HHHHHHHHHHHHHHHHHHHHHH HHHHHHHHHHHHHHHHH HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH HHHHHHHHHHHHHHHHHH H HHHHHHHHHHHHHH H H H HHHHHHHHHHHHHHHHHHHHHH H HHH";
        p.QuantidadeMaximaAcompanhamento = 50;
        p.QuantidadeSelecionada = 0;

        ret.Relacao.push(p);
      }
    }
    else {
      await c().then(res => { ret = (res as AcompanhamentoModel); })
        .catch(e => {
          ret.Sucesso = false;
          ret.Mensagem = "Erro desconhecido realizando esta operação. Tente novamente"
          console.log(e)
        })
        .finally(() => {
          //this.spinnerSrv.Hide();
        });
    }

    //this.spinnerSrv.Hide();
    return ret;
  }

  async IdentificarEmpresa(): Promise<IdentificacaoModel> {
    let c = () => {
      let retorno: IdentificacaoModel = new IdentificacaoModel();

      //Global.UrlChamadora = "https://CARDAPIOHNT.bitfood.com.br/";
      //Global.UrlChamadora = "http://192.168.1.114:5500/";

      return new Promise(async (resolve) => {
        let p = {
          urlChamadora: Global.UrlChamadora
        };

        let jsonFinal: string = JSON.stringify(p);
        let url = Global.UrlServico + 'emp/w/';
        let header = this.ObterHeaderHttp();

        let options = {
          headers: header
        };

        if (!Global.EhProducao) {
          console.log(jsonFinal);
          console.log(url);
        }

        let online = await this.VerificaServicoOnline();

        if (online.Sucesso) {
          await this.http.post(url, jsonFinal, options).subscribe(
            result => {
              if (!Global.EhProducao) {
                console.log('Sucesso!!!');
                console.log(result);
              }

              let r: any = result;
              retorno.Sucesso = r.S;
              retorno.Mensagem = r.M;

              if ((r.S) && (r.O)) {
                let objResp = JSON.parse(r.O);
                retorno.Id = objResp.codigoIdCliente;
                retorno.EmpresaAtiva = objResp.ativa;
                retorno.EhCardapioVirtual = objResp.ehCardapioVirtual;
              }

              resolve(retorno);
            },
            err => {
              retorno.Sucesso = false;

              if (err) {
                err.forEach(e => {
                  retorno.Mensagem += e + "\n";
                });
              }
              else {
                retorno.Mensagem = 'Não foi possível identificar esta empresa';
              }

              resolve(retorno);
            });
        }
        else {
          retorno.Mensagem = online.Mensagem;
          resolve(retorno);
        }
      });
    };

    let ret: IdentificacaoModel = new IdentificacaoModel();
    if (Global.AmbienteMocado) {
      ret.Sucesso = true;
      ret.Mensagem = "";
      ret.Id = "112233";
      ret.EmpresaAtiva = true;
      ret.EhCardapioVirtual = false;
    }
    else {
      await c().then(res => { ret = (res as IdentificacaoModel); })
        .catch(e => {
          ret.Sucesso = false;
          ret.Mensagem = "Erro desconhecido realizando esta operação. Tente novamente"
          console.log(e)
        })
        .finally(() => {
          //this.spinnerSrv.Hide();
        });
    }

    return ret;
  }

  async AlterarSenha(login: string, senha: string): Promise<RetornoModel> {
    let c = () => {
      let retorno: RetornoModel = new RetornoModel();

      return new Promise(async (resolve) => {
        if ((login) &&
          (senha)) {

          let contaDto = {
            id: Global.IdentificacaoEmpresa,
            login: login,
            senha: senha
          };

          let jsonFinal: string = JSON.stringify(contaDto);
          let url = Global.UrlServico + 'aut/senha/';
          let header = this.ObterHeaderHttp();

          let options = {
            headers: header
          };

          if (!Global.EhProducao) {
            console.log(header);
            console.log(options);
            console.log(jsonFinal);
          }

          let online = await this.VerificaServicoOnline();

          if (online.Sucesso) {
            this.spinnerSrv.Show('Alterando senha...');

            await this.http.post(url, jsonFinal, options).subscribe(
              result => {
                //this.spinnerSrv.Hide();

                if (!Global.EhProducao) {
                  console.log('Sucesso!!!');
                  console.log(result);
                }

                let r: any = result;

                if (r.S) {
                  retorno.Sucesso = r.S;
                  retorno.Mensagem = r.M;
                }
                else {
                  retorno.Sucesso = false;
                  retorno.Mensagem = "";
                  if (r.M) {
                    retorno.Mensagem += r.M;
                  }

                  if (r.errors) {
                    for (let i = 0; i < r.errors.length; i++) {
                      retorno.Mensagem += r.errors[i] + "\n";
                    }
                  }
                }

                resolve(retorno);
              },
              err => {
                //this.spinnerSrv.Hide();

                retorno.Mensagem = 'Não foi possível alterar a senha. Tente novamente!';
                retorno.Erro = err;

                //this.alertSrv.Mensagem(retorno.Mensagem);
                resolve(retorno);
              });
          }
          else {
            //this.spinnerSrv.Hide();

            retorno.Mensagem = online.Mensagem;
            resolve(retorno);
          }
        }
        else {
          //this.spinnerSrv.Hide();
          retorno.Mensagem = 'Nenhuma informação de alteração de senha foi informada.';
          resolve(retorno);
        }
      });
    };
    let ret: RetornoModel = new RetornoModel();
    if (Global.AmbienteMocado) {
      ret.Sucesso = true;
      ret.Mensagem = "Senha alterada com sucesso."
    }
    else {
      await c()
        .then(res => {
          ret = (res as RetornoModel);
        })
        .catch(e => {
          ret.Sucesso = false;
          ret.Mensagem = "Erro desconhecido realizando esta operação. Tente novamente"
          console.log(e)
        })
        .finally(() => {
          this.spinnerSrv.Hide();
        });
    }

    //this.spinnerSrv.Hide();
    return ret;
  }

  async AlterarEndereco(login: string,
    cep: string,
    endereco: string,
    bairro: string,
    cidade: string,
    numeroEndereco: string,
    complemento: string,
    telefone: string): Promise<RetornoModel> {
    let c = () => {
      let retorno: RetornoModel = new RetornoModel();

      return new Promise(async (resolve) => {
        if ((login) &&
          (cep) &&
          (numeroEndereco)) {

          let contaDto = {
            id: Global.IdentificacaoEmpresa,
            login: login,
            cep: cep,
            endereco: endereco,
            bairro: bairro,
            cidade: cidade,
            numeroResidencia: numeroEndereco,
            complemento: complemento,
            telefone: telefone
          };

          let jsonFinal: string = JSON.stringify(contaDto);
          let url = Global.UrlServico + 'aut/endereco/';
          let header = this.ObterHeaderHttp();

          let options = {
            headers: header
          };

          if (!Global.EhProducao) {
            console.log(header);
            console.log(options);
            console.log(jsonFinal);
          }

          let online = await this.VerificaServicoOnline();

          if (online.Sucesso) {
            this.spinnerSrv.Show('Alterando endereço...');

            await this.http.post(url, jsonFinal, options).subscribe(
              result => {
                //this.spinnerSrv.Hide();

                if (!Global.EhProducao) {
                  console.log('Sucesso!!!');
                  console.log(result);
                }

                let r: any = result;

                if (r.S) {
                  retorno.Sucesso = r.S;
                  retorno.Mensagem = r.M;
                }
                else {
                  retorno.Sucesso = false;
                  retorno.Mensagem = "";
                  if (r.M) {
                    retorno.Mensagem += r.M;
                  }

                  if (r.errors) {
                    for (let i = 0; i < r.errors.length; i++) {
                      retorno.Mensagem += r.errors[i] + "\n";
                    }
                  }
                }

                resolve(retorno);
              },
              err => {
                //this.spinnerSrv.Hide();

                retorno.Mensagem = 'Não foi possível alterar o endereço. Tente novamente!';
                retorno.Erro = err;

                //this.alertSrv.Mensagem(retorno.Mensagem);
                resolve(retorno);
              });
          }
          else {
            //this.spinnerSrv.Hide();

            retorno.Mensagem = online.Mensagem;
            resolve(retorno);
          }
        }
        else {
          //this.spinnerSrv.Hide();
          retorno.Mensagem = 'Nenhuma informação de alteração de endereço foi informada.';
          resolve(retorno);
        }
      });
    };
    let ret: RetornoModel = new RetornoModel();
    if (Global.AmbienteMocado) {
      ret.Sucesso = true;
      ret.Mensagem = "Ebdereço alterado com sucesso."
    }
    else {
      await c()
        .then(res => {
          ret = (res as RetornoModel);
        })
        .catch(e => {
          ret.Sucesso = false;
          ret.Mensagem = "Erro desconhecido realizando esta operação. Tente novamente"
          console.log(e)
        })
        .finally(() => {
          this.spinnerSrv.Hide();
        });
    }

    //this.spinnerSrv.Hide();
    return ret;
  }

  async ReiniciarSenhaCliente(login: string): Promise<RetornoModel> {
    let c = () => {
      let retorno: RetornoModel = new RetornoModel();

      return new Promise(async (resolve) => {
        if (login) {
          let contaDto = {
            id: Global.IdentificacaoEmpresa,
            login: login
          };

          let jsonFinal: string = JSON.stringify(contaDto);
          let url = Global.UrlServico + 'aut/pReset/';
          let header = this.ObterHeaderHttp();

          let options = {
            headers: header
          };

          if (!Global.EhProducao) {
            console.log(header);
            console.log(options);
            console.log(jsonFinal);
          }

          let online = await this.VerificaServicoOnline();

          if (online.Sucesso) {
            this.spinnerSrv.Show('Recuperando senha...');

            await this.http.post(url, jsonFinal, options).subscribe(
              result => {
                if (!Global.EhProducao) {
                  console.log('Sucesso!!!');
                  console.log(result);
                }

                let r: any = result;

                if (r.S) {
                  retorno.Sucesso = r.S;
                  retorno.Mensagem = r.M;
                }
                else {
                  retorno.Sucesso = false;
                  retorno.Mensagem = "";
                  if (r.M) {
                    retorno.Mensagem += r.M;
                  }

                  if (r.errors) {
                    for (let i = 0; i < r.errors.length; i++) {
                      retorno.Mensagem += r.errors[i] + "\n";
                    }
                  }
                }

                resolve(retorno);
              },
              err => {
                //this.spinnerSrv.Hide();

                retorno.Mensagem = 'Não foi possível recuperar a senha. Tente novamente!';
                retorno.Erro = err;

                //this.alertSrv.Mensagem(retorno.Mensagem);
                resolve(retorno);
              });
          }
          else {
            retorno.Mensagem = online.Mensagem;
            resolve(retorno);
          }
        }
        else {
          retorno.Mensagem = 'Nenhuma informação de recuperação de senha foi informada.';
          resolve(retorno);
        }
      });
    };
    let ret: RetornoModel = new RetornoModel();
    if (Global.AmbienteMocado) {
      ret.Sucesso = true;
      ret.Mensagem = "Senha recuperada com sucesso."
    }
    else {
      await c()
        .then(res => {
          ret = (res as RetornoModel);
        })
        .catch(e => {
          ret.Sucesso = false;
          ret.Mensagem = "Erro desconhecido realizando esta operação. Tente novamente"
          console.log(e)
        })
        .finally(() => {
          this.spinnerSrv.Hide();
        });
    }

    return ret;
  }

  async EnviarSmsAutorizacao(login: string): Promise<RetornoModel> {
    let c = () => {
      let retorno: RetornoModel = new RetornoModel();

      return new Promise(async (resolve) => {
        if (login) {
          let contaDto = {
            id: Global.IdentificacaoEmpresa,
            login: login
          };

          let jsonFinal: string = JSON.stringify(contaDto);
          let url = Global.UrlServico + 'aut/pSms/';
          let header = this.ObterHeaderHttp();

          let options = {
            headers: header
          };

          if (!Global.EhProducao) {
            console.log(header);
            console.log(options);
            console.log(jsonFinal);
          }

          let online = await this.VerificaServicoOnline();

          if (online.Sucesso) {
            this.spinnerSrv.Show('Reenviando SMS...');

            await this.http.post(url, jsonFinal, options).subscribe(
              result => {
                if (!Global.EhProducao) {
                  console.log('Sucesso!!!');
                  console.log(result);
                }

                let r: any = result;

                if (r.S) {
                  retorno.Sucesso = r.S;
                  retorno.Mensagem = r.M;
                }
                else {
                  retorno.Sucesso = false;
                  retorno.Mensagem = "";
                  if (r.M) {
                    retorno.Mensagem += r.M;
                  }

                  if (r.errors) {
                    for (let i = 0; i < r.errors.length; i++) {
                      retorno.Mensagem += r.errors[i] + "\n";
                    }
                  }
                }

                resolve(retorno);
              },
              err => {
                //this.spinnerSrv.Hide();

                retorno.Mensagem = 'Não foi possível reenviar o SMS. Tente novamente!';
                retorno.Erro = err;

                //this.alertSrv.Mensagem(retorno.Mensagem);
                resolve(retorno);
              });
          }
          else {
            retorno.Mensagem = online.Mensagem;
            resolve(retorno);
          }
        }
        else {
          retorno.Mensagem = 'Nenhuma informação de reenvio de SMS foi informada.';
          resolve(retorno);
        }
      });
    };
    let ret: RetornoModel = new RetornoModel();
    if (Global.AmbienteMocado) {
      ret.Sucesso = true;
      ret.Mensagem = "SMS enviado com sucesso."
    }
    else {
      await c()
        .then(res => {
          ret = (res as RetornoModel);
        })
        .catch(e => {
          ret.Sucesso = false;
          ret.Mensagem = "Erro desconhecido realizando esta operação. Tente novamente"
          console.log(e)
        })
        .finally(() => {
          this.spinnerSrv.Hide();
        });
    }

    return ret;
  }

  async CarregarInformacoesFuncionamentoEmpresa(): Promise<HorarioFuncionamentoModel> {
    let c = () => {
      let retorno: HorarioFuncionamentoModel = new HorarioFuncionamentoModel();

      return new Promise(async (resolve) => {
        let p = {
          id: Global.IdentificacaoEmpresa
        };

        let jsonFinal: string = JSON.stringify(p);
        let url = Global.UrlServico + 'emp/hora/';
        let header = this.ObterHeaderHttp();

        let options = {
          headers: header
        };

        let online = await this.VerificaServicoOnline();

        if (online.Sucesso) {
          await this.http.post(url, jsonFinal, options).subscribe(
            result => {
              if (!Global.EhProducao) {
                console.log('Sucesso!!!');
                console.log(result);
              }

              let r: any = result;
              retorno.Sucesso = r.S;
              retorno.Mensagem = r.M;
              retorno.Relacao = [];

              if ((r.S) && (r.O)) {
                let objResp = JSON.parse(r.O);

                objResp.forEach(e => {
                  let p: DetalheHorarioFuncionamentoModel = new DetalheHorarioFuncionamentoModel();
                  p.DiaDaSemana = e.DiaDaSemana;
                  p.HoraFuncionamento = e.HoraFuncionamento;

                  retorno.Relacao.push(p);
                });
              }

              resolve(retorno);
            },
            err => {
              retorno.Sucesso = false;
              retorno.Mensagem = 'Não foi possível carregar informações de horário de funcionmento da empresa';

              resolve(retorno);
            });
        }
        else {
          retorno.Mensagem = online.Mensagem;
          resolve(retorno);
        }
      });
    };

    let ret: HorarioFuncionamentoModel = new HorarioFuncionamentoModel();
    if (Global.AmbienteMocado) {
      ret.Sucesso = true;
      ret.Mensagem = "";
      ret.Relacao = [];

      {
        let p: DetalheHorarioFuncionamentoModel = new DetalheHorarioFuncionamentoModel();
        p.DiaDaSemana = "Domingo";
        p.HoraFuncionamento = "08:00 - 20:00";

        ret.Relacao.push(p);
      }

      {
        let p: DetalheHorarioFuncionamentoModel = new DetalheHorarioFuncionamentoModel();
        p.DiaDaSemana = "Segunda";
        p.HoraFuncionamento = "08:00 - 20:00";

        ret.Relacao.push(p);
      }

      {
        let p: DetalheHorarioFuncionamentoModel = new DetalheHorarioFuncionamentoModel();
        p.DiaDaSemana = "Terça";
        p.HoraFuncionamento = "08:00 - 20:00";

        ret.Relacao.push(p);
      }

      {
        let p: DetalheHorarioFuncionamentoModel = new DetalheHorarioFuncionamentoModel();
        p.DiaDaSemana = "Quarta";
        p.HoraFuncionamento = "08:00 - 20:00";

        ret.Relacao.push(p);
      }

      {
        let p: DetalheHorarioFuncionamentoModel = new DetalheHorarioFuncionamentoModel();
        p.DiaDaSemana = "Quinta";
        p.HoraFuncionamento = "08:00 - 20:00";

        ret.Relacao.push(p);
      }

      {
        let p: DetalheHorarioFuncionamentoModel = new DetalheHorarioFuncionamentoModel();
        p.DiaDaSemana = "Sexta";
        p.HoraFuncionamento = "Fechado";

        ret.Relacao.push(p);
      }

      {
        let p: DetalheHorarioFuncionamentoModel = new DetalheHorarioFuncionamentoModel();
        p.DiaDaSemana = "Sábado";
        p.HoraFuncionamento = "Fechado";

        ret.Relacao.push(p);
      }

    }
    else {
      await c().then(res => { ret = (res as HorarioFuncionamentoModel); })
        .catch(e => {
          ret.Sucesso = false;
          ret.Mensagem = "Erro desconhecido realizando esta operação. Tente novamente"
          console.log(e)
        })
        .finally(() => {
          //this.spinnerSrv.Hide();
        });
    }

    //this.spinnerSrv.Hide();
    return ret;
  }

  async ObterCupomDescontoPeloNome(descricaoCupom: string, valorTotal: number): Promise<RetornoModel> {
    let c = () => {
      let retorno: RetornoModel = new RetornoModel();

      return new Promise(async (resolve) => {
        let p = {
          id: Global.IdentificacaoEmpresa,
          nomeCupom: descricaoCupom,
          valorTotal: valorTotal
        };

        let jsonFinal: string = JSON.stringify(p);
        let url = Global.UrlServico + 'emp/validaCupom/';
        let header = this.ObterHeaderHttp();

        let options = {
          headers: header
        };

        let online = await this.VerificaServicoOnline();

        if (online.Sucesso) {
          //this.spinnerSrv.Show("Validando Cupom de Desconto...");

          await this.http.post(url, jsonFinal, options).subscribe(
            result => {
              if (!Global.EhProducao) {
                console.log('Sucesso!!!');
                console.log(result);
              }

              let r: any = result;
              retorno.Sucesso = r.S;
              retorno.Mensagem = r.M;

              if (r.errors) {
                retorno.Sucesso = false;
                retorno.Mensagem = "";
                r.errors.forEach(e => {
                  retorno.Mensagem += e + "\n";
                });
              }

              if ((r.S) && (r.O)) {
                let objResp = JSON.parse(r.O);

                retorno.Objeto = {
                  CodigoCupomDesconto: objResp.CodigoCupomDesconto,
                  NomeCupom: objResp.NomeCupom,
                  DescricaoCupom: objResp.DescricaoCupom,
                  //TipoCupom: objResp.TipoCupom,
                  ValorDesconto: objResp.ValorDesconto,
                  //DataCupomInicial: objResp.DataCupomInicial,
                  //DataCupomFinal: objResp.DataCupomFinal,
                  //QuantidadeUtilizacaoGeral: objResp.QuantidadeUtilizacaoGeral,
                  //QuantidadeUtilizacaoBitFood: objResp.QuantidadeUtilizacaoBitFood,
                  //ValorMinimo: objResp.ValorMinimo,
                  //QuantidadeUtilizadosPDV: objResp.QuantidadeUtilizadosPDV,
                  //QuantidadeUtilizadosFood: objResp.QuantidadeUtilizadosFood,
                  //QuantidadeUtilizadosBitFood: objResp.QuantidadeUtilizadosBitFood
                  Mensagem: objResp.Mensagem
                }
              }

              resolve(retorno);
            },
            err => {
              retorno.Sucesso = false;
              retorno.Mensagem = 'Não foi possível carregar informações de Cupom de Desconto';

              resolve(retorno);
            });
        }
        else {
          retorno.Mensagem = online.Mensagem;
          resolve(retorno);
        }
      });
    };

    let ret: RetornoModel = new RetornoModel();

    await c().then(res => { ret = (res as RetornoModel); })
      .catch(e => {
        ret.Sucesso = false;
        ret.Mensagem = "Erro desconhecido realizando esta operação. Tente novamente"
        console.log(e)
      })
      .finally(() => {
        //this.spinnerSrv.Hide();
      });

    return ret;
  }

  async ObterPedidoPeloCodigo(codigo: number): Promise<DetalhePedidoModel> {
    let c = () => {
      let detalhe: DetalhePedidoModel = new DetalhePedidoModel;

      return new Promise(async (resolve) => {
        let p = {
          id: Global.IdentificacaoEmpresa,
          codigo: codigo
        };

        let jsonFinal: string = JSON.stringify(p);
        let url = Global.UrlServico + 'carr/ped/';
        let header = this.ObterHeaderHttp();

        let options = {
          headers: header
        };

        let online = await this.VerificaServicoOnline();

        if (online.Sucesso) {
          await this.http.post(url, jsonFinal, options).subscribe(
            result => {
              if (!Global.EhProducao) {
                console.log('Sucesso!!!');
                console.log(result);
              }

              let r: any = result;
              detalhe.Sucesso = r.S;
              detalhe.Mensagem = r.M;

              if (r.errors) {
                detalhe.Sucesso = false;
                detalhe.Mensagem = "";
                r.errors.forEach(e => {
                  detalhe.Mensagem += e + "\n";
                });
              }

              if ((r.S) && (r.O)) {
                let objResp = JSON.parse(r.O);

                detalhe.DataCriacao = objResp[0].dataCriacao;
                detalhe.ValorTotal = objResp[0].valorTotal;
                detalhe.Situacao = objResp[0].situacao;
                detalhe.Codigo = objResp[0].codigo;
                detalhe.MotivoCancelamento = objResp[0].motivoCancelamento;
              }

              resolve(detalhe);
            },
            err => {
              detalhe.Sucesso = false;
              detalhe.Mensagem = 'Não foi possível carregar informações do pedido';

              resolve(detalhe);
            });
        }
        else {
          detalhe.Mensagem = online.Mensagem;
          resolve(detalhe);
        }
      });
    };

    let ret: DetalhePedidoModel = new DetalhePedidoModel();

    await c().then(res => { ret = (res as DetalhePedidoModel); })
      .catch(e => {
        ret.Sucesso = false;
        ret.Mensagem = "Erro desconhecido realizando esta operação. Tente novamente"
        console.log(e)
      })
      .finally(() => {
        //this.spinnerSrv.Hide();
      });

    return ret;
  }

  async RegistrarAcessoAplicacao(): Promise<RetornoModel> {
    let c = () => {
      let retorno: RetornoModel = new RetornoModel();

      return new Promise(async (resolve) => {
        // Monto o objeto que vou serializar no POST
        let p = {
          id: Global.IdentificacaoEmpresa,
        }
        let jsonFinal: string = JSON.stringify(p);

        let url = Global.UrlServico + 'emp/acesso/';
        let header = this.ObterHeaderHttp();
        let options = {
          headers: header
        };

        if (!Global.EhProducao) {
          console.log(header);
          console.log(options);
          console.log(jsonFinal);
        }

        let online = await this.VerificaServicoOnline();

        if (online.Sucesso) {
          await this.http.post(url, jsonFinal, options).subscribe(
            result => {
              if (!Global.EhProducao) {
                console.log('Sucesso!!!');
                console.log(result);
              }

              let r: any = result;
              if (r.S) {
                retorno.Sucesso = r.S;
                retorno.Mensagem = r.ME;
              }
              else {
                retorno.Mensagem = Global.isNullOrEmpty(r.ME)
                  ? r.ME
                  : 'Nenhuma resposta válida do servidor';
              }

              resolve(retorno);
            },
            err => {
              retorno.Mensagem = 'Não foi possível registrar o acesso desta empresa';
              resolve(retorno);
            });
        }
        else {
          retorno.Mensagem = online.Mensagem;
          resolve(retorno);
        }
      });
    };

    let ret: RetornoModel = new RetornoModel();
    if (Global.AmbienteMocado) {
      ret.Sucesso = true;
      ret.Mensagem = '';
    }
    else {
      await c()
        .then(res => {
          ret = (res as RetornoModel);
        })
        .catch(e => {
          ret.Sucesso = false;
          ret.Mensagem = "Erro desconhecido realizando esta operação. Tente novamente"
          console.log(e)
        });
    }
    return ret;
  }
}
